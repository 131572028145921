import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 18rem;

  @media (max-width: ${props => props.theme.devices.desktop}) {
    margin-bottom: 0;
  }
`

const IntroContent = styled.div`
  position: relative;
  font-size: 2.8rem;
  padding-top: 11rem;

  h3 {
    position: relative;
    font-size: 3.6rem;
    font-family: "darker_grotesquebold", sans-serif;
    margin-top: 5rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

const IntroSection = ({ page }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.1}
    >
      <IntroContent>
        {documentToReactComponents(JSON.parse(page.introText.raw))}
      </IntroContent>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  page: PropTypes.object,
}

export default IntroSection
