import React from "react"
import Viewport from "../../components/common/viewport"
import PageContent from "../content/page/page-content"
import SEO from "../seo"

const Page = props => {
  const page = props.pageContext.page

  if (!page) return

  return (
    <Viewport>
      <PageContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default Page

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.page.seoTitle}
    description={pageContext.page.seoDescription}
    keywords={pageContext.page.seoKeywords}
  />
)
