import React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/use-site-metadata"

function SEO({ title, description, pathname, children, keywords, schemaData }) {
  const { description: defaultDescription, image, siteUrl } = useSiteMetadata()

  const seo = {
    title: `${title ? title + " | " : ""} Waynet`,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    keywords: keywords,
  }

  const postDate = schemaData ? new Date(schemaData.createdAt) : null
  const postDay = postDate && postDate.getUTCDay()
  const postMonth = postDate && postDate.getMonth()
  const postYear = postDate && postDate.getFullYear()

  let schemaObject

  if (schemaData) {
    schemaObject = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      headline: `${schemaData.title}`,
      datePublished: `${postYear}-${postMonth}-${postDay}`,
    })
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {keywords && <meta name="keywords" content={seo.keywords} />}
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <link rel="canonical" href={`${seo.url}`} />
      {image && (
        <>
          <meta name="og:image" content={seo.image} />
          <meta name="image" content={seo.image} />
          <meta name="twitter:image" content={seo.image} />
        </>
      )}

      {schemaData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: schemaObject }}
        />
      )}
      {children}
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  schemaData: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
}

export default SEO
